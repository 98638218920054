<template>
    <v-container fluid >
        <v-row dense align-start justify-center row wrap>
            <template v-if="adminSelectOnly||itemNotOrdered">
                <template v-if="!adminSelectOnly">
                    <v-col center cols="12">
                        <ProductInfo
                        :item="item"
                        :show="!readyForPayment"
                        />
                    </v-col>
                </template>
                <template v-if="!disable">

                        <v-col cols="6" class="ml-0">
                            <v-checkbox
                            v-model="termsAccepted" hide-details
                            >
                                <div slot="label" class="body-2" @click.stop="">
                                    Accept the 
                                    <a href="javascript:;" @click.stop="terms = true">Terms</a>
                                </div>
                            </v-checkbox>
                        </v-col>
        
                    <v-col cols="6" center>
                        <v-select v-model="selected" :disabled="cartDisabled"
                            :items="quantities"
                            small-chips hide-details
                            label="Order Quantity"
                        ></v-select> 
                    </v-col>
                
                    <v-col cols="12" v-if="item.fulfillments">
                        <v-radio-group v-model="fulfillment">
                        <v-radio
                            v-for="(f, n) in item.fulfillments"
                            :key="(n-1)"
                            :label="titleCase(f.type)+' @ '+f.location"
                            :value="f"
                        ></v-radio>
                        </v-radio-group>
                        <div class="font-weight-medium">{{fulfillmentTip}}</div>
                    </v-col>

                </template>
                <template v-if="cartLoading">
                    <v-col cols="12" class="text-center" mt-4>
                        <v-progress-circular
                            :width="3"
                            color="blue"
                            indeterminate
                        ></v-progress-circular>
                        Building Secure Payment Form...
                    </v-col>
                </template>
                <template v-else>
                    <v-col center cols="12">
                        <SquareCart
                            v-show="readyForPayment"
                            v-bind:cart="cart"
                            v-bind:showTitle="showTitle"
                            v-bind:showDetails="showDetails"
                        />
                    </v-col>
                </template>
                <template v-if="!adminSelectOnly">
                    <template v-show="!cartLoading">
                        <v-col pa-0 ma-0 cols="12">
                            <form action="purchase">
                                <SquareCheckout 
                                    v-show="readyForPayment" 
                                    :buttonText="purchaseButtonText"
                                    :showPaymentForm=readyForPayment 
                                    :id="index"
                                    @freeze-cart="freezeCart"
                                    @payment-successful="paymentCompleted = true"/>
                            </form>
                        </v-col>
                    </template>
                </template>
                <v-dialog v-model="terms" width="70%">
                    <v-card>
                        <v-card-title class="title">Pre-Order Terms</v-card-title>
                        <v-card-text>
                            By placing a pre-order, purchaser agrees to pick up (or have designated proxy pick up) 
                            purchased products during regular business hours at the selected location within the pickup period: 
                            <br><br>
                            Beginning: {{formatDate(item.pickupStart)}}
                            <br>
                            Ending: {{formatDate(item.pickupEnd)}}
                            <br><br>
                            Any pre-orders not picked up after the end of this period may be cancelled, refunded, and made available 
                            for public sale.
                        </v-card-text>
                        <v-card-actions>
                        <v-btn
                            text
                            color="black"
                            @click="terms = false; termsAccepted = false"
                        >No Thanks</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            color="purple"
                            @click="terms = false; termsAccepted = true"
                        >I Accept</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </template>
            <template v-else>
                <v-col center ma-3 xs10>
                    <v-card flat>
                        <v-card-text>
                        You have already reached the quantity limit for pre-orders of this item ({{item.limit}}). View your
                        <router-link to="orders">Order History</router-link>
                        </v-card-text>
                    </v-card>
                </v-col>
            </template>
            <v-dialog v-model="paymentCompleted" width="70%" persistent>
                    <v-card>
                        <v-card-title class="title">Thank you for your order!</v-card-title>
                        <v-card-text>
                            <strong>{{item.name}}</strong><br>
                            {{"(" + item.selected + ") " + item.size}}<br><br>
                            {{fulfillmentTip}}
                            <br><br>
                            You will need to show the <strong>barcode on your receipt</strong> when you pickup.
                            Your receipt can be found on your orders page.
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="viewOrders"
                            >View My Orders</v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="stayHere"
                            >Stay Here</v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
        </v-row>
    </v-container>
</template>

<script>
import { mapMutations } from 'vuex'
import ProductInfo from '@/components/product/ProductInfo'
import SquareCheckout from '@/components/payment/SquareCheckout'
import SquareCart from '@/components/payment/SquareCart'
import axios from 'axios'
import api from '@/store/api'
import moment from 'moment'
import AllMixins from '@/mixins/AllMixins'
export default {
    data () {
        return {
            alert: false,
            payment: false,
            cartLoading: false,
            checkout: false,
            localCart: null,
            showTitle: false,
            showDetails: false,
            paymentCompleted: false,
            purchaseButtonText: "Purchase",
            terms: false,
            termsAccepted: false,
            selected: 0,
            offerFulfillmentOptions: true,
            fulfillment: null,
            cartDisabled: false,
        }
    },
    mixins: [AllMixins],
    components:   {
        SquareCheckout,
        SquareCart,
        ProductInfo
    },
    props: ['item', 'disable', 'index', 'adminSelectOnly', 'adminRecordForUser'],
    mounted () {
        if(this.item.fulfillments && this.item.fulfillments.length == 1){
            this.fulfillment = this.item.fulfillments[0]
        }
    },
    computed: {
        fulfillmentTip(){
            let tip = ""
            if(this.fulfillment){
                tip = "Your order will be held at " + this.fulfillment.location + " from " + 
                    this.formatDate(this.item.pickupStart) + " until " + this.formatDate(this.item.pickupEnd)
            }
            return tip
        },
        quantities () {
            if(this.item == null)
                return null
            let qtys = []
            let limit = this.item.limit
            let previous = this.previousOrderQty
            if(!limit)
                limit = 1
            limit = limit - previous
            for(var i = 1; i <= limit; i++){
                qtys.push({text: "" + i})
            }
            return qtys
        },
        readyForPayment () {  //payment form is only showed if everything else is entered, valid. submitting payment form submits all
            if (this.termsAccepted && this.selected > 0 && (!this.item.fulfillments || this.fulfillment)){
                if (this.cartChanged()){
                    this.buildOrder()
                }
                return true
            }
            return false
        },
        cart () { return this.$store.state.cart },
        error () { return this.$store.state.error },
        loading () { return this.$store.state.loading },
        termsContent () { 
            return "By placing an order through this portal, you agree to pick up (or have your designated proxy pick up) your pre-order in the Wren House taproom during the Pickup Period (" + this.item.pickupStart + " to " + this.item.pickupEnd + "). Any pre-orders not picked up at the end of this period will be cancelled, fully refunded, and may be made available for public sale thereafter."
        },
        itemNotOrdered () {
            return (this.previousOrderQty < this.item.limit)
        },
        previousOrderQty () {
            //if this is an admin recordOrder, ignore the admin users orders and allow any number
            if (this.adminRecordForUser)
                return 0

            let orders = this.$store.state.userOrders.filter(order => order.status != "Refunded" && order.productId == this.item.productId)
            let previous = 0
            for(var i = 0; i < orders.length; i++) {
                console.log("Previously ordered: " + orders[i].id)
                previous += parseInt(orders[i].line_items[0].quantity)
            }
            return previous
        }
    },
    methods: {
        ...mapMutations([
            'setCart',
            'setLoading',
            'recordOrder'
        ]),
        cartChanged () {
            if(this.localCart == null || this.localCart.line_items[0].quantity != this.selected){
                return true
            }
            return false
        },
        freezeCart(flag){
            this.cartDisabled = flag
            console.log("disabled = " + flag)
        },
        formatDate (dateStr) {
            return moment(dateStr, "YYYY-MM-DD @HH:mm").format("M/D/YY ha")
        },
        buildOrder () { //build essential items to view cart; this will be remade on server to create and process the order
            this.cartLoading = true

            let lineItem = {}
            lineItem.name = this.item.name
            lineItem.quantity = this.selected
            lineItem.base_price_money = { amount: this.item.price * 100, currency: 'USD'}
            
            lineItem.gross_sales_money = { amount: lineItem.base_price_money.amount * lineItem.quantity, currency: 'USD'}
            lineItem.variation_name = this.item.size
            var taxes = {}
            taxes.name = 'Phoenix AZ'
            taxes.percentage = '8.6'
            lineItem.taxes = [taxes]

            let order = {}
            order.product = this.item
            order.product.selected = this.selected
            order.line_items = [lineItem]
            order.total_tax_money = { amount: lineItem.gross_sales_money.amount * 0.086, currency: 'USD'}
            order.total_money = { amount: lineItem.gross_sales_money.amount + order.total_tax_money.amount, currency: 'USD'}
           
            order.name = this.item.name

            order.fulfillment = this.fulfillment

            this.localCart = order

            this.setCart(order)
            this.cartLoading = false

        },
        viewOrders () {
            //redirect to order history
            this.paymentCompleted = false
            this.$router.push('/orders')
        },
        stayHere () {
            api.loadUserOrders() //must reload orders to recalculate remaining quantity
            this.paymentCompleted = false
        },
    }
}
</script>