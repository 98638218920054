<template>
    <v-container fluid>
        <v-row row wrap>

            <v-col cols="12" pl-3>
                <v-btn
                    v-model="enable"
                    block outlined elevation="2"
                    @click="enable=!enable"
                    > Record an Order </v-btn>
            </v-col>

            <template v-if="enable">
                <v-col cols="12" class="pl-4 pr-4 pt-2 mb-0 pb-0">
                    <div class="font-weight-thin">Record an order in botls without taking payment</div>
                </v-col>
                <v-col cols="12" class="pl-4 mt-2 pt-2">
                    
                    <template v-if="loading">
                        <v-progress-circular
                            :width="3"
                            color="blue"
                            indeterminate
                        ></v-progress-circular>
                    </template>
                    
                    <template v-else>
                        <v-select v-model="reason"
                            :items="reasons"
                            label="Select Reason"
                        ></v-select> 
                        <v-select v-model="product"
                            :items="products"
                            item-text="name"
                            label="Select Product"
                            return-object
                        ></v-select> 
                        <template v-if="!hide">
                            <v-card cols="12">
                                <Product 
                                :item="product" 
                                item-key="productId"
                                v-bind:index=0
                                v-bind:adminSelectOnly="true"
                                :adminRecordForUser="true"
                                />
                            </v-card>
                        </template>
                        <template v-if="!recorded">
                            <v-btn 
                            :disabled="!readyToRecord"
                            color="primary" outlined
                            :loading="loading"
                            @click="recordOrder()"
                            >
                            {{"Record Order for " + member.fullName}}
                            </v-btn>
                        </template>
                        <template v-else>
                            Recording complete. Use the "Lookup" page to check the order as recorded.
                            <v-btn 
                            color="primary" outlined
                            @click="reset()"
                            >
                            Reset
                            </v-btn>
                        </template>
 
                    </template>
                </v-col>
            </template>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters} from 'vuex'
import Product from '@/components/product/Product'
import AllMixins from '@/mixins/AllMixins'
import axios from 'axios'
export default {
    name: 'RecordOrder',
    data () {
        return {
            enable: false,
            product: null,
            reasons: [
                "Paid in botls but no record in botls",
                "Paid outside of botls",
            ],
            reason: null,
            recorded: false
        }
    },
    components: {
        Product,
    },
    mixins: [AllMixins],
    mounted () {
    //    this.getAvailableProducts()
    },
    props: ['member'],
    computed: {
        ...mapGetters ([
            'cart', 
            'loading',
            'isActiveMember'
        ]),
        hide () {
            if(this.product == null)
                return true
            return false
        },
        products () {
            let products = this.$store.state.products
            if(!products)
                return []
            if(this.isActiveMember){
                return products 
            }
            else
                return this.filteredProducts
        },
        filteredProducts () {
            let filtered = []
            this.$store.state.products.forEach( function (product) {
                if(product.audience && product.audience == "Current + Future Members"){
                    filtered.push(product)
                }
            })
            return filtered
        },
        readyToRecord () {
            
            if(this.product == null) return false
            let order = this.cart
            if(order == null || order.name == null) return false
            if(order.name != this.product.name) return false
            return true
        }
    },
    methods: {
        ...mapGetters([
            'userInfo',
        ]),
        recordOrder () {
            let self = this

            let order = this.cart
            order.recordedReason = this.reason
            order.recordedBy = this.userInfo().fullName
            self.loading = true
            const functionServer = ENV.functionsServer()
            return new Promise( function(resolve) {
                axios.post(functionServer + "recordcart", 
                { 
                    cart: order, 
                    customer: self.member,
                })
                .then( function(json) {
                    self.loading = false
                    this.product = null
                    self.recorded = true
                    resolve(json);
                })
                .catch( function(error) {
                    console.log(error)
                    self.loading = false
                });
            });     
        },
        reset () {
            this.recorded = false
        }
    }
}
</script>